<template>
  <div v-if="!loading" class="fill-height">

    <support-navigation
        v-if="!selectedItem"
        @select-item="openUpdate"
        :items="items"
        class="mb-4"
        :icon-color="wsACCENT"
    />
    <update-view-support
        v-else
        :uuid="selectedItem"
    />

  </div>
  <div v-else class="fill-height d-flex align-center justify-center">
    <div style="width: 100px">
      <v-progress-linear rounded indeterminate :color="wsACCENT" />
    </div>
  </div>

</template>

<script>
import supportNavigation from "@/modules/tech_support/components/UI/supportNavigation";
import updateViewSupport from "@/modules/tech_support/components/pages/UI/updateViewSupport";
import {mapActions} from "vuex";

export default {
  name: "supportFaq",
  components : {
    supportNavigation,
    updateViewSupport
  },
  props : {
    returnTrigger : {
      type : Number
    },
    scrollPosition : {
      type : Number
    }
  },
  data() {
    return {
      items : [],
      selectedItem : null,
      loading : false,
      scrollBuffer : 0,
    }
  },
  watch : {
    returnTrigger() {
      this.handleReturn()
    },
  },
  methods : {
    ...mapActions('support', [
      'GET_UPDATES',
    ]),

    handleReturn() {
      // Single Hiearchy
      this.selectedItem = null
      this.$emit('update-header' , {name : this.$t('support.updates.title')} )
      let view = document.getElementById('support_chat_content_view')
      this.loading = true
      setTimeout(()=> {
        this.loading = false
        setTimeout(()=> {
          view.scrollTop = this.scrollBuffer || 0;
          this.scrollBuffer = null
        },1)
      },300)



    },

    openUpdate(item) {
      this.selectedItem = item.value
      this.scrollBuffer = this.scrollPosition
      this.$emit('update-header' ,
          { name : item.text || item.type , displayReturn : true , wide : true } )
    },
    async initPage() {
      this.loading = true
      let result = await this.GET_UPDATES()
      this.loading = false
      if ( !result || result === true ) {
        return
      }
      result.items.forEach(item => {
        item.bold = item.to_newsletter
        item.subtitle = this.$t(`support.updates.types.${item.type}`) + ' • ' + this.MONTH_DAY_TIME(item.date , false , true)
      })
      this.items = result.items
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>